import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layouts'

const NotFoundPage = () => (
  <Layout>
    <div className="f f-col a-center">
      <h1>Sorry!</h1>
      <p>We can't find the page that you are looking for.</p>
      <Link to="/">Back to home page</Link>
    </div>
  </Layout>
)

export default NotFoundPage
